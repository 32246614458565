<!-- 招待されたへや -->
<template>
  <h2 class="title is-2">Invited Room.</h2>
  <div class=""></div>
  <ul>
    <li v-for="(invited, roomId) in invitee.rooms" :key="roomId">
      {{ invited }} - {{ roomId }}
      <button
        type="button"
        class="button is-primary"
        @click="onApprove(roomId)"
      >
        承認
      </button>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, reactive, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/firestore'

import { User } from '@/types/user'
import { Room, Invitee, InviteeState } from '@/types/wishlist'
import { roomConverter, inviteeConverter } from '@/converter/wishlistConverter'
import { useStore } from '@/store/index'

export default defineComponent({
  setup() {
    const store = useStore()
    const db = firebase.firestore()
    let invitee = reactive<Invitee>({ rooms: {} })

    const fetchInvitee = async () => {
      if (!store.state.user?.email) {
        return
      }
      const ref = db
        .collection('invitees')
        .doc(store.state.user.email)
        .withConverter(inviteeConverter)

      invitee = Object.assign(invitee, (await ref.get()).data())
    }
    onBeforeMount(async () => {
      await fetchInvitee()
    })

    const onApprove = async (roomId: string) => {
      alert('approve')
      if (!store.state.user?.uid) {
        return
      }

      const roomRef = await db.collection('rooms').doc(roomId)

      const room = (await roomRef.withConverter(roomConverter).get()).data()
      if (!room) {
        alert('Not found room!')
        return
      }

      console.log('before', room.roles)
      room.roles[store.state.user.uid] = 'invitee'
      console.log('update', room.roles)
      roomRef.set({ roles: room.roles }, { merge: true })

      const inviteeRef = db.collection('invitees').doc(store.state.user.email)

      const invitee = (
        await inviteeRef.withConverter(inviteeConverter).get()
      ).data()
      if (!invitee) {
        alert('Not found invitee!')
        return
      }

      invitee.rooms[roomId] = 'approved'

      inviteeRef.set({ rooms: invitee.rooms }, { merge: true })
    }

    return {
      invitee,
      onApprove,
    }
  },
})
</script>
